@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'primaryFonts';
  src: url('./fonts/Gilroy-UltraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'secundaryFonts';
  src: url('./fonts/Gilroy-UltraLight.ttf') format('truetype');
}

body {
  margin: 0;
  font-family:Montserrat /*  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}