.body {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: "primaryFonts";
  }
  
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #eae1cb;
  }
  
  .footer {
    width: 100%;
    background-color: #eae1cb;
    text-align: center;
    padding: 0px;
    position: relative;
    bottom: 0;
  }
  