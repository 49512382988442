.background {
  background-image: url(../assents/video-landing.gif);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  font-family: "primaryFonts";
  font-size: 20px;
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    transition: opacity 1s ease-out;
  }
  .show {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    height:100vh;
    width: 100%;
  font-size: 15px;

  }
  .logo {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    width: 80%;
    display: flex;
    justify-content: center;
  }
  .btnOpen {
    width: 10rem;
    height: 3rem;
    border-radius: 20px;
    border-color: white;
    font-size: 20px;
    color: white;
    background-color: black;
  }
  .text, .logo, .btnOpen {
    opacity: 0;
    transform: translateX(-50px);
    animation: fadeInLeft 3s forwards;
  }
  .text{
    animation-delay: 1s;
  }
  .logo {
    animation-delay: 0s;
  }
  .btnOpen {
    animation-delay: 3s;
  }
  
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
@media (max-width: 1920px){
  .main{
    width: 430px;
    height: 932px;
  }
}

@media (max-height: 640px){
  .show {
    gap: 0px;
    font-size: 12px;
  }
}
@media (max-width: 365px){
  .show {
    gap: 0px;
    font-size: 12px;
  }
}