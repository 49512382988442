.body {
  width: 85%;
  margin-top: 5%;
  margin-right: 2.5%;
  padding: 5%;

  border-radius: 0 20px 20px 0;
  border: white;
  border-width: 1px;
  border-style: solid;

  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.body h1{
    margin: 0;
}
.line {
  width: 100%;
  height: 2px;
  background-color: white;
}

.dishContainer {  
  display: flex;
  flex-direction: column;
  box-sizing: border-box;


}

.sectionContainer {
  width: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
