.body{
    font-family: Montserrat;
    width: 100vw;
    height: 100%;
    padding: 20px;
    background: rgb(66, 64, 64);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-size: medium;
    color: white;
    gap: 20px;
}

.title{
    width: 90%;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 10px;
    text-wrap: wrap;
}
.price{
    margin-left: auto;
    font-weight: bold;
    font-size: 1rem;
    position: relative;
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
}
.img {
    width: 40vw;
    height: 20vh;
    border-style: solid;
    border-width: 5px;
    border-radius: 20px;
    margin-right: 5px;
}
.containerCircle{
    display: flex;
    gap: 10px;
}
.circle{
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.circle img {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    border-width: 1px;
}