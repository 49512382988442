.Style {
  font-family: Montserrat !important;
  width: 100%;
  height: 100vh;
  background-color: white;  
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 20vh); */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-btn {
  width: 250px;  
  height: 50px;  
  border-radius: 10px;
  border-color: white;
  background-color:#076633;  
  font-family: Montserrat;
  color: white;
  align-items: center;
}

.login-input {
  width: 300px;  /* Ajusta el tamaño del campo de entrada */
  height: 40px;  /* Ajusta el tamaño del campo de entrada */
  border-radius: 10px;
  margin: 0.5rem 0;  /* Añadir margen para separación */
}

.login-box {
  background-color: #076633;  /* Color de fondo del cuadro de login */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 350px;  /* Ajusta el tamaño del cuadro */
}

.login-box h1 {
  color: white;
  margin: 0;
  font-size: 2rem;
}

.login-box h1 span {
  display: block;
  font-size: 1rem;
}

.login-box h2 {
  color: white;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: normal;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-btn-container {
  margin-top: 2%;
}

.google-btn-container {
  margin-top: 2%;
}
