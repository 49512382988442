.price{
    display: flex;
    align-items: center;
}
.price input {
    width: 3vw;
}
.price span {
    font-size: medium; 
    font-weight: bold; 
    font-style: oblique;
    margin-right: 10px;
}