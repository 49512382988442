.modal {
  font-family: Montserrat;
  width: 750px;
  height: 590px;
  margin: 20px auto;
  padding: 10px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  font-size: medium;
}

.modal span {
  margin-right: auto;
  font-size: medium;
  font-weight: bold;
  font-style: oblique;
}

.containerTres,
.containerDos,
.containerCinco,
.containerCuatro {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerSeis {
  margin-left: auto;
  height: 159px;
}  
.containerUno,
.containerDos,
.containerTres,
.containerCuatro,
.containerCinco,
.containerSeis {
  border-color: rgba(112, 128, 144, 0.411);
  border-style: solid;
  border-radius: 7px;
  margin: 5px;
  padding: 5px;
}

.deleteBtn {
  background-color: #f1a8af;
}

.deleteBtn:hover {
  background-color: #c82333;
}

.checkBtn {
  background-color: #c1f5cd;
}

.checkBtn:hover {
  background-color: #218838;
}

.btnContainer {
  display: flex;
}

.btnContainer div {
  border: 1px solid #076633;
  width: 5rem;
  height: 1.5rem;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btnContainerTwo {
  display: flex;
}
.btnContainerTwo div {
  border: 1px solid #076633;
  width: 5rem;
  height: 1.5rem;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
