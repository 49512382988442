.abm {
  display: grid;
  height: 100vh;
  grid-template-columns: 1.5fr repeat(6, 1fr) 1.5fr;
  grid-template-rows: repeat(5, 20vh);
}
.title{
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 8;
  display: flex;
  background-color: #076633;
  box-shadow: 0px 10px 10px #0a2b1993;
  justify-content: center;
  align-items: center;
}
.titleOne {
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  width: 100%;
}

.userRol {
  font-family: Montserrat;
  display: flex;
  width: 30%;
  margin-right: auto;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: xx-large;
  font-style: oblique;
  font-weight: bold;
  padding-left: 10px;
  color: white;
}
.buttonsContainer {
  width: 100%;
  height: 100%;
  grid-column: 1;
  grid-row-start: 2;
  grid-row-end: 6;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background-color:#116d3c;

  box-shadow: 5px 5px 10px 5px   #0a2b1993;
  
}
.btnMenus {
  font-family: Montserrat;
  width: 90%;
  height: 7vh;
  font-size: 1.25rem;
  text-align: center;
  grid-column: 1;
  margin: 2.5%;
  box-shadow: 5px 5px 5px 5px   #0a2b1993;
  border-color: #030303c4;
  border-width: 0.2vh;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
  background-color: #32885a;
}


.btnLogout{
  font-family: Montserrat;
  width: 35%;
  height: 4vh;
  text-align: center;
  grid-column: 1;
  margin: 2.5%;
  box-shadow: 2px 2px 2px 2px   #0a2b1993;
  border-color: #030303c4;
  border-width: 0.2vh;
  font-weight: bold;
  font-size: 1rem;
  color: white;
  background-color: #32885a;
}

.btnMenus:hover{
background-color: #076633;
}

.tableContainer {
  height: 100%;
  width: 100%;
  overflow: scroll;
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 6;
}

.buttonStart {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row: 1;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.btn {
  width: 40%;
  margin: 5%;
  height: 7vh;
  font-size: 28px;
}
.modalContainer {
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 6;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 999;
}

.dishContainer {
  grid-row-start: 2;
  grid-row-end: 6;
  grid-column-start: 1;
  grid-column-end: 7;
  display: grid;
  width: 100%;
  min-height: 10vh;
  max-height: 65vh;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}
.btnDishes {
  width: 100%;
  height: 6vh;
  font-size: 28px;
  text-align: left;
}
.loaderErrorCoint {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  grid-column-start: 3;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 6;
}

.optionGrupContainer {
  grid-column: 8;
  grid-row-start: 1;
  grid-row-end: 6;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #4eaa79;
  box-shadow: 10px 10px 10px 10px   #0a2b1993;
  text-align: center;
}

.priceConteiner {
  grid-column: 2;
  position: relative;
  z-index: 0;
}

@media (max-width: 1368px){
  .btnMenus{
    font-size: 16px;
  }
  .btnLogout{
    width: 150px;
  }
  .userRol{
    font-size: medium;
  }
}