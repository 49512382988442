.body{
    width: 100%;
}

.btnContainer{
    height: 5vh;
    display: flex;
    align-items: center;
    padding: 20px;
}

.btn{
    font-family: Montserrat;
    width: 15rem;
    height: 3rem;
    font-size: 1.25rem;
    text-align: center;
    grid-column: 1;
    margin: 2.5%;
    box-shadow: 3px 3px 3px 3px   #0a2b1993;
    border-color: #030303c4;
    border-width: 0.2vh;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
    background-color: #32885a;
}

.btn:hover{
    background-color: #076633;
    }

.body table {
    width: 100%;
    border-color: #32885a8a;
    border-style: solid;
}

.body tr {
    width: 100%;
    border-top: solid;
    border-color: #32885a8a;
}
