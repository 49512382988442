.dishContainer {
  display: flex;
  align-items: center; 
  justify-content: center;
}
.switchConteiner {
  position: relative;
  z-index: 0;
}
.priceConteiner {
  position: relative;
  z-index: 0;
}
.btnDishes {
  color: #82b399;
}
.btnDishes:hover{
  color: #076633;
}

.dishContainer span {
  font-size: medium; 
  font-weight: bold; 
  font-style: oblique;
}