.body {
  width: 85%;
  margin-top: 5%;
  margin-left: 2.5%;
  padding: 5%;

  background-color: rgba(255, 255, 255, 0.712);
  border-radius: 20px 0px 0px 20px;
  border-width: 1px;
  border-style: solid;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.body h1{
  margin: 0;
}

.line {
  width: 100%;
  height: 2px;
}

.sectionContainer {
  width: 100%;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.dishContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
