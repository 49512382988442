  
    .checkBtn:hover {
    color: #11441c;
  }
.checkBtn{
  color: #218838;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #076633;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;

}
  
  .btnContainer{
    
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }  