/* Estilos generales para el componente de filtro */
.filterComponent {
    position: fixed;
    color: white;
    top: 0;
    left: 0;
    width: 60px;
    height: 520px;
    margin-top: 50px;
    background-color: #f5f5f5; 
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
    padding: 20px;
    z-index: 1000;
    overflow-y: auto; 
    border-color: white;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    border-radius: 0px 0px 50px 50px;
  }
  /* Estilo para el contenedor de los íconos */
.filterComponent div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    font-size: 12px;
  }
  
  /* Estilo para los íconos de filtro */
  .filterIcon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: filter 0.3s ease, border 0.3s ease;
    border-radius: 50%;
    border: 2px solid rgb(255, 255, 255);
    filter: none;
  }
  /* Estilo para el borde cuando el ícono está activo */
  .filterIcon[active="true"] {
    filter: grayscale(100%);
  }
  /* Estilo específico para el ícono de vegano */
  .filterIcon.vegano {
    filter: none;
  }
  
  .filterIcon.vegano[active="false"] {
    filter: grayscale(100%);
  }
  
  @media (max-height: 610px){
    .filterComponent{
      height: 100vh;
    }
}

  @media (max-height: 590px){
    .filterComponent{
      height: 100vh;
    }
    .filterIcon{
      width: 22px;
      height: 22px;
    }
    .filterComponent div{
      gap: 8px;
    }
}
@media (max-height: 530px){
  .filterComponent{
    width: 100%;
    height: 120px;
    border-width: 0px 0px 1px 0px;
    border-radius: 0px 0px 0px 0px;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .filterIcon{
    width: 22px;
    height: 22px;
  }
  .filterComponent div{
    flex-wrap: wrap;
    gap: 8px;
    flex-direction: row;
  }
}
.iconContainer{
width: 70px;
flex-direction: column !important;
}