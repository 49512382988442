.body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
    height: auto;
    padding: 10px;
}
.containInfor{
    width: 215px;
    display: flex;
}
.containContacts{
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-items: right;
    align-items: right;
    justify-content: right;
}
.img{
    width: 30px;
    height: 30px;
    margin-left: 20px;
    display: flex;
    justify-items: right;
}
.imgdos{
    width: 25px;
    height: 25px;
    margin-left: 20px;
    justify-items: right;
}
.contain{
    display: flex;
    justify-items: right;
    align-items: center;
    justify-content: right;
}
.text{
    text-decoration: none;
    color: white;
    text-align: center;
}
.containInfor{
    display: flex;
    flex-direction: column;
    justify-items: left;
    align-items: left;
    color: white;
    margin-left: 5px;
}
