.body{
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    flex-wrap: nowrap;
}
.conteiner{
    width: 55px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    text-align: center;
}
.img{
    width: 35px;
    height: 35px;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
}

@media (max-width: 425px){
    .body{
        flex-wrap: wrap;
    }
}
