.modal{
    position: fixed;
    z-index: 888;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
    justify-content: center;
    align-items: start;
}

.modal.isOpen{
    display: flex;
}

.modal-container{
    margin-top: 10vh;
    position: relative;
    min-width: 300px;
    max-width: 100%;
    min-height: 300px;
    max-height: 100%;
    background-color: white;

    border-color: #313131;
    border-style: solid;
    border-radius: 20px;
    border-width: 0.5vh;
    z-index: 999;
}
.modal-close{
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.btn {
    font-size: medium;
    margin: 5%;
    width: 5vw;
    height: 3vh;
    border-radius: 1vh;
    border-color:  #438863;
    background-color: white;
    text-align: center;
    font-weight: bolder;
    color: #076633;
    border-width: 0.3vh;
  }
  @media (max-height: 700px){
    .modal-container{
        margin-top: 0px;
    }
  }