.body {
  width: 95%;
  border-color: rgba(255, 255, 255, 0.63);
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  background-color: rgba(201, 194, 194, 0.288);
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 2.5%;
  font-family: "secundaryFonts";
}

.titleContainer {
  width: 100%;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
}
.title {
  width: 75%;
  margin-left: auto;
  font-size: 1.5rem;
  text-align: end;
}
.titleContainerTwo {
    width: 100%;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: auto;
  }
  .titleTwo {
    width: 75%;
    margin-right: auto;
    font-size: 1.5rem;
    text-align: start;
  }
.price {
  margin-left: auto;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img {
  width: 28vw;
  height: 14vh;
  border-style: solid;
  border-width: 2px;
  border-radius: 20px;
}
.containerDos {
  display: flex;
  flex-direction: column;
}
.text {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.containerCircle {
  display: flex;
  width: 77vw;
}
.circle {
  width: 2.5vh;
  height: 2.5vh;
  border-radius: 50%;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  margin-right: 5px;
}

@media (max-width: 415px){
  .price{
flex-wrap: wrap;
  }
}