.btnContainer{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    background-color: #EEE2CC;
}

.btnRigth, .btnLeft{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-style: solid;
    border-color: white;
    border-width: 1px;
}
.btnRigth{
    border-right: 0;
    border-radius: 10px 0px 0px 10px;
}
 .btnLeft{
    border-radius: 0px 10px 10px 0px;
    border-left: 0;
 }