.ck-editor__editable_inline {
height: auto;
width: auto;
  overflow-y: auto;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-blurred) {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.ck.ck-editor__top {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  background: #f1f1f1;
  padding: 5px;
  
}

.ck.ck-editor__top .ck-toolbar {
  display: flex;
  justify-content: space-between;
}

.ck.ck-editor__editable_inline.ck-read-only {
  background: #f9f9f9;
  
  
}

.ck-editor__top {
  width: 100% !important;
  box-sizing: border-box;
}

.ck-toolbar {
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
}


.ck-content {
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  overflow-y: scroll;
}



.ck-editor {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}