.switch-button {
    display: inline-block;
    margin-left: 1.875rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}
.switch-button span {
    font-weight: bold;
    font-size: large;
}
.switch-button .switch-button__checkbox {
    display: none;
}
.switch-button .switch-button__label {
    background-color:  #fe5e41;
    width: 5rem;
    height: 1rem;
    border-radius: 3rem;
    display: inline-block;
    position: relative;
}
.switch-button .switch-button__label:before {
    transition: .2s;
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: #fdffff;
    content: '';
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 1px #000;
}
.switch-button .switch-button__checkbox:checked + .switch-button__label {
    background-color:   #00a878 ;
}
.switch-button .switch-button__checkbox:checked + .switch-button__label:before {
    transform: translateX(4rem);
}

.switch-button span {
    font-size: medium; 
    font-weight: bold; 
    font-style: oblique;
    margin-right: 10px;
}